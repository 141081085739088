import headerLogo from "../../../assets/jpeg/headerlogo.jpeg";
import classes from "./header.module.scss";
const Header = () => {
  return (
    <div className={classes.container}>
      <img
        alt="logo"
        src={headerLogo}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      />
      <button
        onClick={() => {
          if (
            !navigator.userAgent.includes("Chrome") &&
            navigator.userAgent.includes("Safari")
          ) {
            window.open(
              "https://apps.apple.com/us/app/balloch-park-guide/id6451413021"
            );
          } else {
            window.open(
              "https://play.google.com/store/apps/details?id=com.parkdirection"
            );
          }
        }}
        className={classes.btn}
      >
        Download Now
      </button>
    </div>
  );
};

export default Header;
