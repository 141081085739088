import React from "react";
import classes from "./Footer.module.scss";
import footerLogo from "../../../assets/jpeg/headerlogo.jpeg";
import googlePlay from "../../../assets/png/googleplay.png";
import appStore from "../../../assets/png/appstore.png";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div className={classes.maincontainer}>
      <div className={classes.container}>
        <div className={classes.col1}>
          <img
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            src={footerLogo}
            alt="footerlogo"
          />
        </div>
        <div className={classes.col3}>
          <span>Quick Links</span>
          <NavLink className={classes.navlink} to="/termandcondition">
            <p>Term and Conditions</p>
          </NavLink>
          <NavLink className={classes.navlink} to="/privacypolicy">
            {" "}
            <p>Privacy Policy</p>
          </NavLink>
          <a href="/#contact" className={classes.navlink}>
            <p>Contact Us</p>
          </a>
        </div>
        <div className={classes.col3}>
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.parkdirection"
              )
            }
            src={googlePlay}
            alt="googleplaylogo"
          />
          <img
            onClick={() => {
              window.open(
                "https://apps.apple.com/us/app/balloch-park-guide/id6451413021"
              );
            }}
            src={appStore}
            alt="appstorelogo"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
