import React from "react";
import classes from "./Howitworks.module.scss";
import workpng from "../../../assets/png/howitworks.png";
import mappng from "../../../assets/png/map.png";
import fairytail from "../../../assets/png/farytail.png";
const HowitWorks = ({ content }: any) => {
  return (
    <div className={classes.maincontainer}>
      {content.map((item: any) => {
        return (
          <>
            {item?.section === 3 && (
              <>
                <section className={classes.section}>
                  <h1>{item?.heading}</h1>
                </section>{" "}
              </>
            )}
            {item?.section === 4 && (
              <div className={classes.container}>
                <div className={classes.col1}>
                  <section className={classes.select_marker}>
                    <h1>{item?.heading}</h1>
                    <div className={classes.description}>
                      {" "}
                      <p>{item?.text}</p>{" "}
                    </div>
                  </section>{" "}
                </div>
                <div>
                  <img src={item?.images[0]?.image_url} alt="howitwork" />
                </div>
              </div>
            )}
            {item?.section === 5 && (
              <div className={classes.container}>
                <div>
                  <img src={item?.images[0]?.image_url} alt="howitwork" />
                </div>
                <div className={classes.col2}>
                  <section className={classes.select_marker}>
                    <h1>{item?.heading}</h1>
                    <div className={classes.description}>
                      {" "}
                      <p>{item?.text}</p>{" "}
                    </div>
                  </section>{" "}
                </div>
              </div>
            )}
            {item?.section === 6 && (
              <div className={classes.container}>
                <div className={classes.col1}>
                  <section className={classes.select_marker}>
                    <h1>{item?.heading}</h1>
                    <div className={classes.description}>
                      {" "}
                      <p>{item?.text}</p>{" "}
                    </div>
                  </section>{" "}
                </div>
                <div>
                  <img src={item?.images[0]?.image_url} alt="howitwork" />
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default HowitWorks;
