import { useState, useEffect } from "react";
import MainView from "./view/MainView";
import Section from "./section/Section";
import classes from "../style.module.scss";
import HowitWorks from "./section/HowitWorks";
import Button from "../../components/button/button";
import ContactUs from "./contact/ContactUs";
import { HTTP_CLIENT } from "../../utils/config";
import { ENDPOINTS } from "../../utils/helpers";
import { useLocation, useParams } from "react-router-dom";

const MainPage = () => {
  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);
  const [cardContent, setCardContent] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location?.hash) {
        var scrollToElemet = document.getElementById("contact");
        scrollToElemet?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 1000);
  }, []);

  useEffect(() => {
    HTTP_CLIENT.get(ENDPOINTS.GETALL).then((response) => {
      setData(response.data.result);
    });
    HTTP_CLIENT.get(ENDPOINTS.GETCONTENTS).then((response) => {
      setContent(response.data.result);
    });
    HTTP_CLIENT.get(ENDPOINTS.GETCONTENTSCARDS).then((response) => {
      setCardContent(response.data.result);
    });
  }, []);
  const [visible, setVisible] = useState(false);

  const handletoggle = () => {
    const scroll = document.documentElement.scrollTop;
    if (scroll > 300) {
      setVisible(true);
    } else if (scroll <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", handletoggle);
  return (
    <>
      <MainView content={content} />
      <Section cardContent={cardContent} content={content} />
      <Button data={data} />
      <HowitWorks content={content} />
      <div id="contact">
        <ContactUs />
      </div>
      <button
        onClick={scrollToTop}
        type="button"
        className={visible ? classes.btn : classes.inVisible}
        id="btn-back-to-top"
      >
        <i className="fas fa-arrow-up"></i>
      </button>
    </>
  );
};

export default MainPage;
