import classes from "./MainView.module.scss";
import { ICard } from "../../../models/ICard";
const MainView = ({ content }: ICard) => {
  const backgroundImage =
    content && content?.find((item: any) => item?.section === 9);
  const heading = content && content?.find((item: any) => item?.section === 1);
  const description2 =
    content && content?.find((item: any) => item?.section === 10);
  const description3 =
    content && content?.find((item: any) => item?.section === 11);
  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <img src={backgroundImage?.images[0]?.image_url} />
        <div className={classes.content}>
          {heading && <h3>{heading?.heading}</h3>}
          {heading && (
            <div className={classes.description}>
              <span>{heading?.text}</span>
            </div>
          )}
          {description2 && (
            <div className={classes.description}>
              <span>{description2?.text}</span>
            </div>
          )}
          {description3 && (
            <div className={classes.description}>
              <span>{description3?.text}</span>
            </div>
          )}
          <div className={classes.buttons}>
            <button
              onClick={() => {
                if (
                  !navigator.userAgent.includes("Chrome") &&
                  navigator.userAgent.includes("Safari")
                ) {
                  window.open(
                    "https://apps.apple.com/us/app/balloch-park-guide/id6451413021"
                  );
                } else {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.parkdirection"
                  );
                }
              }}
              className={classes.btn1}
            >
              Download App Now{" "}
            </button>
            <button className={classes.btn2}>Learn More </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainView;
